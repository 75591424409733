import Head from "next/head";
import * as prismic from "@prismicio/client";
import { SliceZone } from "@prismicio/react";

import { createClient } from "@/prismicio";
import { components } from "@/slices/";
import { Layout } from "@/components/Layout";
import PageHeading from "@/components/PageHeading/PageHeading";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

import Meta from "../shared/Meta";

const Index = ({ page, navigation, settings }) => {
  return (
    <Layout
      navigation={navigation}
      settings={settings}
      headerStyle={page.data.header_style}
      logoStyle={page.data.logo_style}
    >
      {prismic.asText(page.data.title) && (
        <PageHeading title={page.data.title} />
      )}

      <Meta
        meta_title={page.data.seo_page_title || ""}
        meta_description={page.data.seo_meta_description || ""}
        exclude_from_sitemap={false}
        no_follow={false}
        no_index={false}
      />

      <SliceZone slices={page.data.slices} components={components} />
    </Layout>
  );
};

export default Index;

export async function getStaticProps({ locale, previewData }) {
  const client = createClient({ previewData });

  const page = await client.getByUID("page", "home", { lang: locale });
  const navigation = await client.getSingle("navigation", { lang: locale });
  const settings = await client.getSingle("settings", { lang: locale });

  return {
    props: {
      page,
      navigation,
      settings,
    },
  };
}
